a {
  color: white; }
  a:hover {
    color: chartreuse; }

.container {
  margin-top: 50px;
  margin-bottom: 220px;
  margin-left: auto;
  margin-right: auto;
  width: 80%; }
  @media screen and (min-width: 600px) {
    .container {
      margin-bottom: 180px !important; } }
  .container .title {
    font-family: 'Amatic SC', cursive;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    font-size: 50px; }
  .container .tags-section .tags-title {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: 'Space Mono', monospace;
    color: #fff;
    font-weight: bold;
    font-size: 14px; }
  .container .tags-section .tags {
    margin: 0;
    padding: 0;
    right: 24px;
    bottom: -14px;
    list-style: none; }
  .container .tags-section .tags li, .container .tags-section .tags a {
    float: left;
    line-height: 24px;
    position: relative;
    font-size: 12px;
    font-family: 'Space Mono', monospace; }
  .container .tags-section .tags a {
    padding: 0 12px 0 10px;
    cursor: pointer;
    color: #fff;
    background-color: rgba(6, 27, 59, 0.341176);
    text-decoration: none;
    margin: 2px;
    -moz-border-radius-bottomright: 30px;
    -webkit-border-bottom-right-radius: 30px;
    border-bottom-right-radius: 30px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px; }
  .container .tags-section .tags a:hover {
    color: #23ff00; }
  .container .tags-section .tags a:hover:before {
    border-color: transparent #1540ce transparent transparent; }

.footer-section {
  text-align: center;
  color: #fff;
  font-family: 'Open Sans Condensed', sans-serif;
  margin: 0 auto;
  width: 80%;
  border-top: 1px solid rgba(245, 243, 243, 0.160784);
  border-bottom: 1px solid rgba(245, 243, 243, 0.160784);
  margin-bottom: 10px; }

.ReactBash {
  min-height: 400px;
  font-family: 'Share Tech Mono', monospace !important; }
  @media screen and (min-width: 600px) {
    .ReactBash {
      font-size: 15px !important; } }
  .ReactBash *:focus {
    outline: none; }
  .ReactBash div:first-child {
    background-color: #1047ca !important; }
  .ReactBash div:nth-child(2) {
    background-color: rgba(0, 0, 0, 0.56) !important; }
    .ReactBash div:nth-child(2) :not([id]) {
      background-color: #061b3b !important;
      color: #23ff00 !important; }
    .ReactBash div:nth-child(2) div span {
      color: #23ff00 !important; }
    .ReactBash div:nth-child(2) form span {
      color: #23ff00 !important; }
